import React, { useEffect, useState } from "react"
import GrillaEvento from "../evento/grillaEvento"

const BuscarScreen = ({ eventosData, variablesEntorno, searchTextParam }) => {
  const [state, setState] = useState({
    eventos: [...eventosData],
    filtradoXArea: [],
    gruposMostrados: 1,
    searchText: "",
  })

  useEffect(() => {
    if (searchTextParam) {
      const palabrasClave = searchTextParam.trim().split(" ")

      let response = []

      palabrasClave.forEach(p => {
        if (p.length) {
          const filtrado = eventosData.filter(e =>
            e.Titulo.toLowerCase().includes(p.toLowerCase())
          )
          filtrado.forEach(e => {
            const existe = response.find(n => n.id === e.id)
            if (!existe) {
              response.push(e)
            }
          })
        }
      })
      setState(s => ({
        ...s,
        eventos: [...response],
        searchText: searchTextParam.trim(),
      }))
    }
  }, [searchTextParam, eventosData])

  return (
    <>
      <div className="section-events">
        <div className="container-1200 w-container">
          <div className="head-title-search">
            <h1 className="h2-title-home search-resultados">
              Resultados de búsqueda
            </h1>
            {state.searchText.length > 0 && (
              <p>
                Se encontraron {state.eventos.length} actividades próximas
                relacionadas a{" "}
                <strong>
                  <em>&quot;{state.searchText}&quot; </em>
                </strong>
              </p>
            )}
          </div>
          <GrillaEvento
            state={state}
            setState={setState}
            variablesEntorno={variablesEntorno}
          />
        </div>
      </div>
    </>
  )
 
}

export default BuscarScreen
