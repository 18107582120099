import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { useLocation } from "@reach/router"
import BuscarScreen from "../components/buscar/BuscarScreen"

const BuscarPage = ({ data, params }) => {
  // const { searchText } = params
  const location = useLocation()

  const { state } = location

  let searchText = ""
  if (state) {
    if (state.searchText) {
      searchText = state.searchText
    }
  }

  const eventosData = data.allApiExternaEventosNext.edges.map(edge => edge.node)

  const variablesEntorno = {
    AGENDA_APP_SITE: data.site.siteMetadata.AGENDA_APP_SITE,
  }

  return (
    <Layout>
      <SEO title="Buscar" pathname="/buscar" />

      <BuscarScreen
        eventosData={eventosData}
        variablesEntorno={variablesEntorno}
        searchTextParam={searchText}
      />
    </Layout>
  )
}

export default BuscarPage

export const pageQuery = graphql`
  query BuscarQuery {
    site {
      siteMetadata {
        title
        AGENDA_APP_SITE
      }
    }
    allApiExternaEventosNext(filter: { id: { ne: "dummy" } }) {
      edges {
        node {
          slug
          EventoDestacado
          ImagenDestacada {
            alternativeText
            alternative_id
            ext
            name
            mime
            height
            provider
            size
            url
            width
          }
          Titulo
          Fechas {
            Fecha
            Fin
            Inicio
            _xcomponent
            alternative_id
          }
          WorkFlow
          alternative_id
          area_tematicas {
            Nombre
            alternative_id
          }
          tipo_evento {
            Nombre
            TipoEvento
            alternative_id
          }
          id
          Lugar {
            DescripcionLink
            DescripcionLugar
            URL
            Ubicacion
            agenda_master_lugar_pucp {
              Nombre
              alternative_id
            }
            alternative_id
            _xcomponent
          }
        }
      }
    }
  }
`
